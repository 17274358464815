<template>
  <el-dialog
    :close-on-click-modal="false"
    v-elDragDialog
    :title="$t('deviceModal.selectCustomer')"
    v-loading="loading"
    :visible.sync="show"
    width="820px"
  >
    <div class="header">
      <div class="right">
        <el-checkbox v-model="isAll" @change="allHandler">{{ $t('publishApp.whole') }}</el-checkbox>
      </div>
    </div>

    <div class="content">
      <el-checkbox-group v-model="checks" @change="changHandler" v-if="list.length">
        <el-checkbox
          class="item"
          v-for="item in list"
          :key="item.id"
          :label="item.id"
          :disabled="item.deviceTotal == 0"
        >
          <div class="item-left">
            <div class="name">{{ item.name }}（{{ item.deviceTotal }}）</div>
          </div>
        </el-checkbox>
      </el-checkbox-group>
      <p class="no-data" v-else>{{ $t('noData') }}</p>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit">{{ $t('confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['selectData', 'versionId'],
  components: {},
  data() {
    return {
      name: '',
      list: [],
      isAll: false,
      checks: [],
      checkData: [],
      loading: false
    }
  },
  computed: {},
  async created() {
    await this.getData()

    if (this.selectData) {
      this.checkData = [...this.selectData]
      this.init()
    }
  },
  methods: {
    init() {
      this.checks = this.selectData.map(item => item.id)
    },
    async getData() {
      try {
        this.loading = true
        const res = await this.$api.otaManagement.listDeviceNumByVersionId({
          versionId: this.versionId
        })

        this.list = res.result.map(item => {
          return {
            name: item.spName,
            id: item.spId,
            deviceTotal: item.deviceNumber
          }
        })
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    changHandler() {
      this.checkData = this.checks.map(val => {
        return this.list.find(item => item.id === val)
      })
    },
    allHandler(bool) {
      if (bool) {
        this.checkData = [...this.list]
        this.checks = this.checkData.map(item => item.id)
      } else {
        this.checkData = []
        this.checks = []
      }
    },
    submit() {
      if (!this.checkData.length) {
        return this.$message.warning(this.$t('serviceAccountManagement.pleaseSelectCustomer'))
      }
      this.$emit('update:selectData', this.checkData)
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    button {
      margin-left: 10px;
    }
  }
}
.content {
  margin-top: 10px;
  height: 500px;
  overflow: auto;
  position: relative;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  ::v-deep .el-checkbox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    .el-checkbox__label {
      flex: 1;
    }
  }
  .item {
    margin-right: 12px;
    margin-top: 10px;
    width: 150px;
    border: 1px solid #ccc;
    padding: 10px;
    overflow: hidden;
    border-radius: 4px;
    .item-left {
      display: flex;
      flex: 1;
      flex-direction: column;
      line-height: 18px;
      .name {
        font-size: 13px;
      }
      .num {
        font-size: 12px;
        color: #ccc;
      }
    }
  }
}
</style>
